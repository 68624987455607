<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box">
						<el-input clearable type="text" placeholder="岗位名称/编号" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">岗位</div>
					<div class="inputs_box">
						<el-cascader placeholder="请选择" :options="bm_list" collapse-tags :props="props" :show-all-levels="false"
							v-model="bm_list_ac" clearable></el-cascader>
					</div>
				</div>
				 
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					 
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" >
					<el-table-column prop="title" label="岗位名称" width="150"></el-table-column>
					<el-table-column prop="sn" label="岗位编号" width=200></el-table-column>
					<el-table-column prop="num" label="定岗人数" width="100"></el-table-column>
					<el-table-column prop="xy_num" label="现有人数" width="100"></el-table-column>
					<el-table-column prop="zj_num" label="增加人数" width="100"></el-table-column>
					<el-table-column prop="bm_text" label="所属组织" ></el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
 


	export default {
		components: {
			page,
	 
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				//提交数据
				forms: {
					keyword: '', //关键字
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
				item_row: {}, //选中的

				props: {
					multiple: false,
					value: 'id',
					label: 'title'
				},

				//选中的岗位
				bm_list_ac: [],
				//岗位列表
				bm_list: [],
                bm_all:[],

			};
		},

		computed: {},
		watch: {
			bm_list(value) {
				//this.$log('watch bm_list', value)
			}
		},
		created() {
			this.get_config()
			this.page_title = this.$route.meta.title
			this.get_bm_list()
		},
		mounted() {

		},


		methods: {
			get_config() {
				this.yglx_list = this.get_vuex_type(11); //用工类型
				this.jz_list = this.get_vuex_type(12);
			},


			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			 
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				let parent_id=''
                if(this.bm_list_ac.length>0){
                    parent_id=this.bm_list_ac[this.bm_list_ac.length-1]
                }
				this.$api("getCategoryList2", {
					 is_gw:1,
                     parent_id:parent_id,
					...this.pages,
					...this.forms,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
                        this.list.forEach((e,i)=>{
                            // this.get_zz_title(e)
                            this.$set(e,'bm_id',[e.parent_id])
                            this.$set(e,'bm_title',[])
                            this.get_zz_title(e,i)

                        })

					} else {
						alertErr(res.msg)
					}
				});
			},
            get_zz_title(row,i){
                // this.bm_all.forEach((e)=>{
                //     e.forEach((v)=>{
                        
                //     })
                // })
             
                for (let i = 0; i <this.bm_all.length ; i++) {
                    const data = this.bm_all[this.bm_all.length-i-1];
                    console.log(data)
                    data.forEach((e)=>{
                        if(row.bm_id[0]==e.id){
                            row.bm_id.unshift(e.parent_id)
                            row.bm_title.unshift(e.title)
                        }
                    })
                   
                    
                }
                this.list[i].bm_text=row.bm_title.join('/')
            },


			//获取部门
			get_bm_list() {

				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						let data =[]
                        res.data.forEach((e)=>{
                            if(e.is_gw!=1){
                                this.$set(e,'children',[])
                                data.push(e)
                            }
                        })
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
						})
						console.log('最高登记', level)


						for (let i = 1; i <= level; i++) {
							let level_list = []
							let level_list2 = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}

							})
							list.push(level_list)
						}
						console.log('列表1', list)
                        this.bm_all=list
						 

						// 总列表  最后一位的下标
						this.bm_list_data(list, list.length - 1)
					 

					

					} else {
						alertErr(res.msg)
					}
				});
			},



			//处理岗位列表
			bm_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
                    list[index2].forEach((v) => {
                        if (v.id == e.parent_id) {
                            v.children.push(e)                   
						}
                        
					})
                    if (e.children.length== 0) {
                        this.$delete(e, 'children')

					}
				})
				list[index2].forEach((e) => {
					if (e.children.length== 0) {
                        // this.$delete(e, 'children')

					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.bm_list = list[0]
			        this.get_list()
					return
				}
				this.bm_list_data(list, index2)
			},
			 
		},


	};
</script>

<style scoped lang="less">
	.page_box {
		// min-height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 9px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 60px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					margin-right: 48px;
					margin-bottom: 20px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
						width: 100px;
					}

					.inputs_box {
						width: 236px;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}

						/deep/.el-select {
							width: 236px;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}

				.search_flex:nth-child(4n) {
					margin-right: 0;
				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 10px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-left: 16px;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>